<template>
  <v-content class="my-app-style">
    <router-view />

    <dashboard-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  };
</script>
<style scoped>
.my-app-style {
  background-color:#fff;
}
</style>
